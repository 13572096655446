/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
	Box,
	Button,
	Center,
	SimpleGrid,
	Text,
	useToast,
} from '@chakra-ui/react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../export-panel/export/export.scss'
import Select from 'react-select'
import {
	useLoadReportClusterList,
	useLoadReportFilterList,
	useLoadReportGeographyList,
	useLoadReportTypeList,
	useReportProcessList,
	useReportRolesList,
	useReportTimeScaleList,
} from '../../../hooks/report.hooks'
import {
	compareAsc,
	addMonths,
	subMonths,
	startOfDay,
	endOfDay,
	startOfMonth,
	endOfMonth,
	startOfQuarter,
	endOfQuarter,
	addQuarters,
	subQuarters,
} from 'date-fns'
// import {
// 	dataLayerTagManager,
// 	eventLabelTrack,
// 	sectionDetailedTrack,
// } from '../../repository/repo.utils'
import { useLanguageContext } from '../../../context/locale.context'
import { GA_CATEGORY_REPORT } from './../../repository/repo.utils'

const customStyles = {
	control: (styles, { isDisabled }) => {
		return {
			...styles,
			backgroundColor: isDisabled ? '#c0c0c0' : 'white',
			color: '#fff',
			borderColor: '#e6e6e6',
			borderRadius: '6px',
			margin: '5px 0 10px',
			width: '160px',
		}
	},
	option: (styles, { isDisabled }) => {
		return {
			...styles,
			backgroundColor: '#fff',
			color: '#000000',
			textAlign: 'left',
			width: '100%',
		}
	},
	menuPortal: provided => ({ ...provided, zIndex: 9999 }),
	menu: provided => ({ ...provided, zIndex: 9999 }),
}

function ReportFilter({ addReport, isLoading }) {
	const { mutate: mutateLoadReportList, data: reportList } =
		useLoadReportTypeList()
	const { mutate: mutateLoadReportFilters, data: reportFilterList } =
		useLoadReportFilterList()
	const { mutate: mutateLoadReportProcess, data: reportProcessList } =
		useReportProcessList()
	const { mutate: mutateLoadReportCluster, data: reportClusterList } =
		useLoadReportClusterList()
	const { mutate: mutateLoadReportGeography, data: reportGeographyList } =
		useLoadReportGeographyList()
	const { mutate: mutateLoadReportRoles, data: reportRolesList } =
		useReportRolesList()
	const { mutate: mutateLoadReportTimeScale, data: reporTimeScaleList } =
		useReportTimeScaleList()
	const {
		state: { locale },
	} = useLanguageContext()

	const [reportFilterValues, setReportFilterValues] = useState({
		startDateTime: startOfMonth(new Date()),
		endDateTime: endOfDay(new Date()),
	})

	const toast = useToast()

	useEffect(() => {
		if (!reportList) {
			mutateLoadReportList(null, {
				onSuccess: data =>
					setReportFilterValues(prevData => ({
						...prevData,
						selectedReport: data[0],
					})),
			})
		}
	}, [])

	useEffect(() => {
		if (
			!!reportFilterValues.selectedReport &&
			reportFilterValues.selectedReport.value !== -1
		) {
			resetFilter()
			const { tag_type_id, value: report_id } =
				reportFilterValues.selectedReport
			mutateLoadReportFilters({ tag_type_id, report_id })
		}
	}, [reportFilterValues.selectedReport])

	useEffect(() => {
		if (!!reportFilterList && reportFilterList.length) {
			const { tag_type_id } = reportFilterValues.selectedReport
			mutateLoadReportProcess(tag_type_id, {
				onSuccess: data =>
					setReportFilterValues(prevData => ({
						...prevData,
						selectedProcess: data[0],
					})),
			})

			if (reportFilterList.find(filter => filter.filter_id === 2)) {
				mutateLoadReportCluster(
					{},
					{
						onSuccess: data => {
							setReportFilterValues(prevData => ({
								...prevData,
								selectedCluster: data[0],
							}))
						},
					}
				)
			}

			const roleFilter = reportFilterList.find(
				filter => filter.filter_id === 25
			)

			if (!!roleFilter) {
				mutateLoadReportRoles(roleFilter, {
					onSuccess: data => {
						setReportFilterValues(prevData => ({
							...prevData,
							selectedRole: data[0],
						}))
					},
				})
			}

			const timeScaleFilter = reportFilterList.find(
				filter => filter.filter_id === 16
			)
			if (!!timeScaleFilter) {
				mutateLoadReportTimeScale(timeScaleFilter, {
					onSuccess: data =>
						setReportFilterValues(prevData => ({
							...prevData,
							selectedTimescale: data[0],
						})),
				})
			}
		}
	}, [reportFilterList])

	useEffect(() => {
		if (!!reportFilterValues.selectedCluster) {
			mutateLoadReportGeography(reportFilterValues.selectedCluster.value, {
				onSuccess: data =>
					setReportFilterValues(prevData => ({
						...prevData,
						selectedBuilding: data[0],
					})),
			})
		}
	}, [reportFilterValues.selectedCluster])

	const manageDateKeyboardSpin = e => {
		e.preventDefault()
	}

	const resetFilter = () => {
		setReportFilterValues({
			startDateTime: startOfMonth(new Date()),
			endDateTime: endOfDay(new Date()),
			selectedReport: reportFilterValues.selectedReport,
		})
	}

	const handleStartDateChange = date => {
		const result = compareAsc(
			new Date(reportFilterValues.endDateTime),
			new Date(date)
		)
		if (result === -1) {
			setReportFilterValues(prevData => ({
				...prevData,
				startDateTime: date,
				endDateTime: date,
			}))
		} else {
			setReportFilterValues(prevData => ({
				...prevData,
				startDateTime: date,
			}))
		}
	}

	const handleTimeLineChange = selectedOption => {
		let startDate, endDate
		let today = new Date()
		startDate = startOfDay(today)
		endDate = endOfDay(today)
		if (selectedOption.value !== 0) {
			//Month
			if (selectedOption.value === 1) {
				startDate = startOfMonth(today)
				endDate = endOfDay(today)
			}
			//Current Quater
			if (selectedOption.value === 2) {
				startDate = startOfQuarter(today)
				endDate = endOfQuarter(today)
			}
			//Last Month
			if (selectedOption.value === 3) {
				startDate = startOfMonth(subMonths(today, 1))
				endDate = endOfMonth(startDate)
			}
			//Last Quater
			if (selectedOption.value === 4) {
				startDate = startOfQuarter(subQuarters(today, 1))
				endDate = endOfQuarter(startDate)
			}
			//Next Quater
			if (selectedOption.value === 5) {
				startDate = startOfQuarter(addQuarters(today, 1))
				endDate = endOfQuarter(startDate)
			}
			//Next 6 Months
			if (selectedOption.value === 6) {
				startDate = endOfDay(today)
				endDate = endOfMonth(addMonths(today, 6))
			}
		}
		setReportFilterValues(prevData => ({
			...prevData,
			selectedTimescale: selectedOption,
			startDateTime: startDate,
			endDateTime: endDate,
		}))
	}

	const isValueEmpty = item => {
		return !item || item.value === -1
	}

	const isValidCheck = () => {
		return reportFilterList?.every(filter => {
			let flagValid = { error: false, label: '' }
			switch (filter.filter_id) {
				case 2:
					if (isValueEmpty(reportFilterValues.selectedCluster)) {
						flagValid = { error: true, label: filter.tag_type_filter_label }
					}
					break
				case 3:
					if (isValueEmpty(reportFilterValues.selectedBuilding)) {
						flagValid = { error: true, label: filter.tag_type_filter_label }
					}
					break
				case 16:
					if (isValueEmpty(reportFilterValues.selectedTimescale)) {
						flagValid = { error: true, label: filter.tag_type_filter_label }
					}
					break
				case 8:
					if (isValueEmpty(reportFilterValues.selectedProcess)) {
						flagValid = { error: true, label: filter.tag_type_filter_label }
					}
					break
				case 17:
					let isStartDateDisabled =
						!!reportFilterValues.selectedTimescale &&
						reportFilterValues.selectedTimescale.value !== 0
							? true
							: false
					if (
						!isStartDateDisabled &&
						isValueEmpty(reportFilterValues.startDateTime)
					) {
						flagValid = { error: true, label: filter.tag_type_filter_label }
					}
					break
				case 18:
					let isEndDateDisabled =
						!!reportFilterValues.selectedTimescale &&
						reportFilterValues.selectedTimescale.value !== 0
							? true
							: false
					if (
						!isEndDateDisabled &&
						isValueEmpty(reportFilterValues.endDateTime)
					) {
						flagValid = { error: true, label: filter.tag_type_filter_label }
					}
					break
				case 25:
					if (isValueEmpty(reportFilterValues.selectedRole)) {
						flagValid = { error: true, label: filter.tag_type_filter_label }
					}
					break
				default:
					break
			}
			if (flagValid.error) {
				toast({
					description: `${flagValid.label} cannot be empty`,
					status: 'error',
					duration: 2000,
					isClosable: true,
					position: 'top',
				})
				return false
			}
			return true
		})
	}

	const submitReport = () => {
		// eventLabelTrack({
		// 	categoryId: 4,
		// 	actionId: 0,
		// 	label: locale['Submitting Reports'],
		// })
		if (isValidCheck()) addReport(reportFilterValues)
	}

	return (
		<SimpleGrid columns={6} spacingX={4} spacingY={2} minChildWidth='200px'>
			<Box>
				<Text>{locale['Reports']}</Text>
				<Select
					value={reportFilterValues.selectedReport}
					onChange={e => {
						setReportFilterValues(prevData => ({
							...prevData,
							selectedReport: e,
						}))
					}}
					name='reports'
					options={reportList}
					styles={customStyles}
				/>
			</Box>

			{!!reportFilterList && reportFilterList.length
				? reportFilterList.map((filterItem, i) => {
						switch (filterItem.filter_id) {
							case 2:
								return (
									<Box>
										<Text>{filterItem.tag_type_filter_label}</Text>
										<Select
											isSearchable={false}
											name='cluster'
											value={reportFilterValues.selectedCluster}
											onChange={e => {
												setReportFilterValues(prevData => ({
													...prevData,
													selectedCluster: e,
												}))
											}}
											options={reportClusterList}
											styles={customStyles}
										/>
									</Box>
								)
							case 3:
								return (
									<Box>
										<Text>{filterItem.tag_type_filter_label}</Text>
										<Select
											isSearchable={false}
											name='circle'
											value={reportFilterValues.selectedBuilding}
											onChange={e => {
												setReportFilterValues(prevData => ({
													...prevData,
													selectedBuilding: e,
												}))
											}}
											options={reportGeographyList}
											styles={customStyles}
										/>
									</Box>
								)
							case 16:
								return (
									<Box>
										<Text>{filterItem.tag_type_filter_label}</Text>
										<Select
											isSearchable={false}
											name='timescale'
											value={reportFilterValues.selectedTimescale}
											onChange={e => {
												handleTimeLineChange(e)
											}}
											options={reporTimeScaleList}
											styles={customStyles}
										/>
									</Box>
								)
							case 8:
								return (
									<Box>
										<Text>{filterItem.tag_type_filter_label}</Text>
										<Select
											isSearchable={false}
											name='processes'
											value={reportFilterValues.selectedProcess}
											onChange={e => {
												setReportFilterValues(prevData => ({
													...prevData,
													selectedProcess: e,
												}))
											}}
											options={reportProcessList}
											styles={customStyles}
										/>
									</Box>
								)
							case 17:
								let isStartDateDisabled =
									!!reportFilterValues.selectedTimescale &&
									reportFilterValues.selectedTimescale.value !== 0
										? true
										: false
								return (
									<Box w='160px'>
										<Text>{filterItem.tag_type_filter_label}</Text>
										<DatePicker
											dateFormat='dd/MM/yyyy'
											onChangeRaw={manageDateKeyboardSpin}
											className={
												isStartDateDisabled ? 'date-css disabled' : 'date-css'
											}
											selected={reportFilterValues.startDateTime}
											onChange={e => handleStartDateChange(e)}
											required
											minDate={subMonths(new Date(), 24)}
											maxDate={addMonths(new Date(), 6)}
											styles={customStyles}
											disabled={isStartDateDisabled}
										/>
									</Box>
								)
							case 18:
								let isEndDateDisabled =
									!!reportFilterValues.selectedTimescale &&
									reportFilterValues.selectedTimescale.value !== 0
										? true
										: false
								return (
									<Box w='160px'>
										<Text>{filterItem.tag_type_filter_label}</Text>
										<DatePicker
											dateFormat='dd/MM/yyyy'
											onChangeRaw={manageDateKeyboardSpin}
											className={
												isEndDateDisabled ? 'date-css disabled' : 'date-css'
											}
											selected={reportFilterValues.endDateTime}
											onChange={e =>
												setReportFilterValues(prevData => ({
													...prevData,
													endDateTime: e,
												}))
											}
											required
											minDate={
												!!reportFilterValues.startDateTime &&
												reportFilterValues.startDateTime
											}
											maxDate={addMonths(reportFilterValues.startDateTime, 6)}
											disabled={isEndDateDisabled}
										/>
									</Box>
								)
							case 25:
								return (
									<Box>
										<Text>{filterItem.tag_type_filter_label}</Text>
										<Select
											isSearchable={false}
											name='processes'
											value={reportFilterValues.selectedRole}
											onChange={e => {
												setReportFilterValues(prevData => ({
													...prevData,
													selectedRole: e,
												}))
											}}
											options={reportRolesList}
											styles={customStyles}
										/>
									</Box>
								)
							default:
								return null
						}
				  })
				: null}
			<Box pt={5}>
				<Button
					bg={localStorage.getItem('color')}
					minW='150px'
					color='white'
					isLoading={isLoading}
					isDisabled={
						!!reportFilterValues.selectedReport &&
						reportFilterValues.selectedReport.value === -1
					}
					onClick={() => {
						// sectionDetailedTrack({
						// 	category: GA_CATEGORY_REPORT,
						// 	action: 'Report Data',
						// 	label: 'Submit',
						// })
						// dataLayerTagManager('button_click', {
						// 	viewName: 'Report',
						// 	buttonName: 'Submit',
						// })
						submitReport()
					}}
				>
					{locale['Submit']}
				</Button>
			</Box>
		</SimpleGrid>
	)
}

export default ReportFilter
