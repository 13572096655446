/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from 'react'
import {
	Box,
	Button,
	Center,
	SimpleGrid,
	Text,
	useToast,
	Tooltip,
} from '@chakra-ui/react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../export-panel/export/export.scss'
import Select from 'react-select'
import { FcClearFilters } from 'react-icons/fc'
import { RiFilterOffLine } from 'react-icons/ri'
import {
	useLoadReportClusterList,
	useLoadReportFilterList,
	useLoadReportGeographyList,
	useLoadReportTypeList,
	useLoadReportTypeLists,
	useReportProcessList,
	useReportRolesList,
	useReportTimeScaleList,
} from '../../../hooks/report.hooks'
import {
	compareAsc,
	addMonths,
	subMonths,
	startOfDay,
	endOfDay,
	startOfMonth,
	endOfMonth,
	startOfQuarter,
	endOfQuarter,
	addQuarters,
	subQuarters,
	startOfWeek,
	endOfWeek,
	sub,
} from 'date-fns'
// import {
// 	dataLayerTagManager,
// 	eventLabelTrack,
// 	sectionDetailedTrack,
// } from '../../repository/repo.utils'
import { useLanguageContext } from '../../../context/locale.context'
import { GA_CATEGORY_REPORT } from './../../repository/repo.utils'

const customStyles = {
	control: (styles, { isDisabled }) => {
		return {
			...styles,
			backgroundColor: isDisabled ? '#c0c0c0' : 'white',
			color: '#fff',
			borderColor: '#e6e6e6',
			borderRadius: '6px',
			margin: '5px 0 10px',
			width: '160px',
		}
	},
	option: (styles, { isDisabled }) => {
		return {
			...styles,
			backgroundColor: '#fff',
			color: '#000000',
			textAlign: 'left',
			width: '100%',
		}
	},
	menuPortal: provided => ({ ...provided, zIndex: 9999 }),
	menu: provided => ({ ...provided, zIndex: 9999 }),
}

function ReportFilters({
	loadReportsData,
	isLoading,
	reportFilterValues,
	setReportFilterValues,
	clearFilters,
}) {
	const { mutate: mutateLoadReportList, data: reportList } =
		useLoadReportTypeList()
	const { mutate: mutateLoadReportLists, data: reportLists } =
		useLoadReportTypeLists()
	const { mutate: mutateLoadReportFilters, data: reportFilterList } =
		useLoadReportFilterList()
	const { mutate: mutateLoadReportProcess, data: reportProcessList } =
		useReportProcessList()
	const { mutate: mutateLoadReportCluster, data: reportClusterList } =
		useLoadReportClusterList()
	const { mutate: mutateLoadReportGeography, data: reportGeographyList } =
		useLoadReportGeographyList()
	const { mutate: mutateLoadReportRoles, data: reportRolesList } =
		useReportRolesList()
	const { mutate: mutateLoadReportTimeScale, data: reporTimeScaleLists } =
		useReportTimeScaleList()
	const {
		state: { locale },
	} = useLanguageContext()

	// const [reportFilterValues, setReportFilterValues] = useState({
	// 	startDateTime: startOfDay(new Date()),
	// 	endDateTime: endOfDay(new Date()),
	// 	selectedDateType: {
	// 		value: 0,
	// 		label: 'All',
	// 		tag_type_id: 0,
	// 	},
	// 	selectedTimescale: {
	// 		value: 0,
	// 		label: 'Custom',
	// 		tag_type_id: 0,
	// 	},
	// })

	const toast = useToast()

	useEffect(() => {
		if (!reportLists) {
			mutateLoadReportLists(null, {
				onSuccess: data =>
					setReportFilterValues(prevData => ({
						...prevData,
						selectedReport: data[0],
					})),
			})
		}
	}, [])

	useEffect(() => {
		if (
			!!reportFilterValues.selectedReport &&
			reportFilterValues.selectedReport.value !== -1
		) {
			resetFilter()
			const { tag_type_id, value: report_id } =
				reportFilterValues.selectedReport
			mutateLoadReportFilters({ tag_type_id, report_id })
		}
	}, [reportFilterValues.selectedReport])

	// useEffect(() => {
	// 	if (!!reportFilterList && reportFilterList.length) {
	// 		const { tag_type_id } = reportFilterValues?.selectedReport
	// 		mutateLoadReportProcess(tag_type_id, {
	// 			onSuccess: data =>
	// 				setReportFilterValues(prevData => ({
	// 					...prevData,
	// 					selectedProcess: data[0],
	// 				})),
	// 		})

	// 		if (reportFilterList.find(filter => filter.filter_id === 2)) {
	// 			mutateLoadReportCluster(
	// 				{},
	// 				{
	// 					onSuccess: data => {
	// 						setReportFilterValues(prevData => ({
	// 							...prevData,
	// 							selectedCluster: data[0],
	// 						}))
	// 					},
	// 				}
	// 			)
	// 		}

	// 		const roleFilter = reportFilterList.find(
	// 			filter => filter.filter_id === 25
	// 		)
	// 		setReportFilterValues(prevData => ({
	// 			...prevData,
	// 			selectedDateType: {
	// 				value: 0,
	// 				label: 'All',
	// 				tag_type_id: 0,
	// 			},
	// 			selectedTimescale: {
	// 				value: 0,
	// 				label: 'Custom',
	// 				tag_type_id: 0,
	// 			},
	// 		}))

	// 		if (!!roleFilter) {
	// 			mutateLoadReportRoles(roleFilter, {
	// 				onSuccess: data => {
	// 					setReportFilterValues(prevData => ({
	// 						...prevData,
	// 						selectedRole: data[0],
	// 					}))
	// 				},
	// 			})
	// 		}

	// 		const timeScaleFilter = reportFilterList.find(
	// 			filter => filter.filter_id === 16
	// 		)
	// 		// if (!!timeScaleFilter) {
	// 		// 	mutateLoadReportTimeScale(timeScaleFilter, {
	// 		// 		onSuccess: data =>
	// 		// 			setReportFilterValues(prevData => ({
	// 		// 				...prevData,
	// 		// 				selectedTimescale: data && data[0],
	// 		// 			})),
	// 		// 	})
	// 		// }
	// 	}
	// }, [reportFilterList])

	// useEffect(() => {
	// 	if (!!reportFilterValues.selectedCluster) {
	// 		mutateLoadReportGeography(reportFilterValues.selectedCluster.value, {
	// 			onSuccess: data =>
	// 				setReportFilterValues(prevData => ({
	// 					...prevData,
	// 					selectedBuilding: data[0],
	// 				})),
	// 		})
	// 	}
	// }, [reportFilterValues.selectedCluster])

	const manageDateKeyboardSpin = e => {
		e.preventDefault()
	}

	const resetFilter = () => {
		// setReportFilterValues({
		// 	startDateTime: startOfMonth(new Date()),
		// 	endDateTime: endOfDay(new Date()),
		// 	selectedReport: reportFilterValues.selectedReport,
		// })
	}

	const handleStartDateChange = date => {
		const result = compareAsc(
			new Date(reportFilterValues.endDateTime),
			new Date(date)
		)
		if (result === -1) {
			setReportFilterValues(prevData => ({
				...prevData,
				startDateTime: date,
				endDateTime: date,
			}))
		} else {
			setReportFilterValues(prevData => ({
				...prevData,
				startDateTime: date,
			}))
		}
	}

	const handleTimeLineChange = selectedOption => {
		let startDate, endDate
		let today = new Date()
		startDate = startOfDay(today)
		endDate = endOfDay(today)
		const getYesterday = date => sub(date, { days: 1 })
		const getLast7DaysRange = () => {
			const today = new Date()
			const startDate = sub(today, { days: 6 }) // Subtract 6 days to get the start date of the last 7 days
			const endDate = today // End date is today

			return {
				startDate7: startOfDay(startDate),
				endDate7: endOfDay(endDate),
			}
		}
		const getLastWeek = () => {
			const today = new Date()
			const startDate = startOfWeek(sub(today, { weeks: 1 }), {
				weekStartsOn: 1,
			}) // Subtract 1 week to get the last Monday
			const endDate = endOfWeek(sub(today, { weeks: 1 }), { weekStartsOn: 1 }) // Subtract 1 week to get the last Sunday

			return {
				startDateW: startOfDay(startDate),
				endDateW: endOfDay(endDate),
			}
		}

		if (selectedOption.value !== 0) {
			//YesterDay
			if (selectedOption.value === 1) {
				startDate = getYesterday(today)
				endDate = getYesterday(today)
			}
			//Last 7 days
			if (selectedOption.value === 2) {
				const { startDate7, endDate7 } = getLast7DaysRange()
				startDate = startDate7
				endDate = endDate7
			}
			//Last Week
			if (selectedOption.value === 3) {
				const { startDateW, endDateW } = getLastWeek()
				startDate = startDateW
				endDate = endDateW
			}
			//Last 30days
			if (selectedOption.value === 4) {
				startDate = sub(today, { days: 30 })
				endDate = startOfDay(today)
			}
			//Last Month
			if (selectedOption.value === 5) {
				startDate = startOfMonth(subMonths(today, 1))
				endDate = endOfMonth(startDate)
			}
		}
		setReportFilterValues(prevData => ({
			...prevData,
			selectedTimescale: selectedOption,
			startDateTime: startDate,
			endDateTime: endDate,
		}))
	}

	const isValueEmpty = item => {
		return !item || item.value === -1
	}

	const isValidCheck = () => {
		return reportFilterList?.every(filter => {
			let flagValid = { error: false, label: '' }
			switch (filter.filter_id) {
				case 2:
					if (isValueEmpty(reportFilterValues.selectedCluster)) {
						flagValid = { error: true, label: filter.tag_type_filter_label }
					}
					break
				case 3:
					if (isValueEmpty(reportFilterValues.selectedBuilding)) {
						flagValid = { error: true, label: filter.tag_type_filter_label }
					}
					break
				case 16:
					if (isValueEmpty(reportFilterValues.selectedTimescale)) {
						flagValid = { error: true, label: filter.tag_type_filter_label }
					}
					break
				case 8:
					if (isValueEmpty(reportFilterValues.selectedProcess)) {
						flagValid = { error: true, label: filter.tag_type_filter_label }
					}
					break
				case 17:
					let isStartDateDisabled =
						!!reportFilterValues.selectedTimescale &&
						reportFilterValues.selectedTimescale.value !== 0
							? true
							: false
					if (
						!isStartDateDisabled &&
						isValueEmpty(reportFilterValues.startDateTime)
					) {
						flagValid = { error: true, label: filter.tag_type_filter_label }
					}
					break
				case 18:
					let isEndDateDisabled =
						!!reportFilterValues.selectedTimescale &&
						reportFilterValues.selectedTimescale.value !== 0
							? true
							: false
					if (
						!isEndDateDisabled &&
						isValueEmpty(reportFilterValues.endDateTime)
					) {
						flagValid = { error: true, label: filter.tag_type_filter_label }
					}
					break
				case 25:
					if (isValueEmpty(reportFilterValues.selectedRole)) {
						flagValid = { error: true, label: filter.tag_type_filter_label }
					}
					break
				default:
					break
			}
			if (flagValid.error) {
				toast({
					description: `${flagValid.label} cannot be empty`,
					status: 'error',
					duration: 2000,
					isClosable: true,
					position: 'top',
				})
				return false
			}
			return true
		})
	}
	// eslint-disable-next-line no-sparse-arrays
	const reporTimeScaleList = [
		{
			value: 0,
			label: 'Custom',
			tag_type_id: 0,
		},
		{
			value: 1,
			label: 'Yesterday',
			tag_type_id: 1,
		},
		{
			value: 2,
			label: 'Last 7 days',
			tag_type_id: 2,
		},
		{
			value: 3,
			label: 'LastWeek',
			tag_type_id: 3,
		},
		{
			value: 4,
			label: 'Last 30 days',
			tag_type_id: 4,
		},
		,
		{
			value: 5,
			label: 'Last Month',
			tag_type_id: 5,
		},
	]

	const submitReport = () => {
		// eventLabelTrack({
		// 	categoryId: 4,
		// 	actionId: 0,
		// 	label: locale['Submitting Reports'],
		// })
		//	if (isValidCheck())
		loadReportsData()
	}
	let isStartDateDisabled =
		!!reportFilterValues.selectedTimescale &&
		reportFilterValues.selectedTimescale.value !== 0
			? true
			: false
	let isEndDateDisabled =
		!!reportFilterValues.selectedTimescale &&
		reportFilterValues.selectedTimescale.value !== 0
			? true
			: false
	return (
		<Box p={2}>
			<SimpleGrid columns={7} spacingX={4} spacingY={2} minChildWidth='150px'>
				<Fragment>
					<Box>
						<Text>{'ReportType'}</Text>
						<Select
							value={reportFilterValues.selectedReport}
							onChange={e => {
								setReportFilterValues(prevData => ({
									...prevData,
									selectedReport: e,
								}))
							}}
							name='reports'
							options={reportLists}
							styles={customStyles}
							classNamePrefix='react-select'
						/>
					</Box>
					<Box>
						<Text>{'DateType'}</Text>
						<Select
							value={reportFilterValues.selectedDateType}
							onChange={e => {
								setReportFilterValues(prevData => ({
									...prevData,
									selectedDateType: e,
								}))
							}}
							name='DateType'
							options={[
								// {
								// 	value: 0,
								// 	label: 'All',
								// 	tag_type_id: 0,
								// },
								{
									value: 1,
									label: 'Submitted Date',
									tag_type_id: 1,
								},
								{
									value: 2,
									label: 'Generated Date',
									tag_type_id: 2,
								},
							]}
							styles={customStyles}
						/>
					</Box>
					<Box>
						<Text>{'Timescale'}</Text>
						<Select
							isSearchable={false}
							name='timescale'
							value={reportFilterValues.selectedTimescale}
							onChange={e => {
								handleTimeLineChange(e)
							}}
							options={reporTimeScaleList}
							styles={customStyles}
						/>
					</Box>
					<Box>
						<Text mt='1'>{'From Date'}</Text>
						<DatePicker
							dateFormat='dd/MM/yyyy'
							onChangeRaw={manageDateKeyboardSpin}
							className={
								isStartDateDisabled
									? ' filter-date-input date-css disabled'
									: ' filter-date-input date-css'
							}
							selected={reportFilterValues.startDateTime}
							onChange={e => handleStartDateChange(e)}
							required
							minDate={subMonths(new Date(), 24)}
							maxDate={addMonths(new Date(), 6)}
							styles={customStyles}
							disabled={isStartDateDisabled}
						/>
					</Box>
					<Box>
						<Text mt='1'>{'ToDate'}</Text>
						<DatePicker
							dateFormat='dd/MM/yyyy'
							onChangeRaw={manageDateKeyboardSpin}
							className={
								isEndDateDisabled
									? 'filter-date-input date-css disabled'
									: ' filter-date-input date-css'
							}
							selected={reportFilterValues.endDateTime}
							onChange={e =>
								setReportFilterValues(prevData => ({
									...prevData,
									endDateTime: e,
								}))
							}
							required
							minDate={
								!!reportFilterValues.startDateTime &&
								reportFilterValues.startDateTime
							}
							maxDate={addMonths(reportFilterValues.startDateTime, 6)}
							disabled={isEndDateDisabled}
						/>
					</Box>
					<Box mt='3' display='flex' alignItems='center'>
						<Button
							mt='1'
							bg={localStorage.getItem('color')}
							minW='120px'
							color='white'
							isLoading={isLoading}
							isDisabled={
								!!reportFilterValues.selectedReport &&
								reportFilterValues.selectedReport.value === -1
							}
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_REPORT,
								// 	action: 'Report Data',
								// 	label: 'Submit',
								// })
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Report',
								// 	buttonName: 'Submit',
								// })
								submitReport()
							}}
						>
							Filter
						</Button>
						<Tooltip label={'Clear Filter'} placement='top'>
							<Box position='relative' display='inline-block' ml={4}>
								<RiFilterOffLine
									fontSize='30px'
									cursor={'pointer'}
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_REPORT,
										// 	action: 'Report Data',
										// 	label: 'Submit',
										// })
										// dataLayerTagManager('button_click', {
										// 	viewName: 'Report',
										// 	buttonName: 'Submit',
										// })

										setTimeout(() => {
											clearFilters()
										}, 100)
									}}
								/>
							</Box>
						</Tooltip>
					</Box>
				</Fragment>
				{/* {!!reportFilterList && reportFilterList.length
        ? reportFilterList.map((filterItem, i) => {
            switch (filterItem.filter_id) {
              case 2:
                return (
                  <Center>
                    <Box>
                      <Text>{filterItem.tag_type_filter_label}</Text>
                      <Select
                        isSearchable={false}
                        name="cluster"
                        value={reportFilterValues.selectedCluster}
                        onChange={e => {
                          setReportFilterValues(prevData => ({
                            ...prevData,
                            selectedCluster: e,
                          }))
                        }}
                        options={reportClusterList}
                        styles={customStyles}
                      />
                    </Box>
                  </Center>
                )
              case 3:
                return (
                  <Center>
                    <Box>
                      <Text>{filterItem.tag_type_filter_label}</Text>
                      <Select
                        isSearchable={false}
                        name="circle"
                        value={reportFilterValues.selectedBuilding}
                        onChange={e => {
                          setReportFilterValues(prevData => ({
                            ...prevData,
                            selectedBuilding: e,
                          }))
                        }}
                        options={reportGeographyList}
                        styles={customStyles}
                      />
                    </Box>
                  </Center>
                )
              case 16:
                return (
                  <Center>
                    <Box>
                      <Text>{filterItem.tag_type_filter_label}</Text>
                      <Select
                        isSearchable={false}
                        name="timescale"
                        value={reportFilterValues.selectedTimescale}
                        onChange={e => {
                          handleTimeLineChange(e)
                        }}
                        options={reporTimeScaleList}
                        styles={customStyles}
                      />
                    </Box>
                  </Center>
                )
              case 8:
                return (
                  <Center>
                    <Box>
                      <Text>{filterItem.tag_type_filter_label}</Text>
                      <Select
                        isSearchable={false}
                        name="processes"
                        value={reportFilterValues.selectedProcess}
                        onChange={e => {
                          setReportFilterValues(prevData => ({
                            ...prevData,
                            selectedProcess: e,
                          }))
                        }}
                        options={reportProcessList}
                        styles={customStyles}
                      />
                    </Box>
                  </Center>
                )
              case 17:
                let isStartDateDisabled =
                  !!reportFilterValues.selectedTimescale &&
                  reportFilterValues.selectedTimescale.value !== 0
                    ? true
                    : false
                return (
                  <Center>
                    <Box>
                      <Text>{filterItem.tag_type_filter_label}</Text>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        onChangeRaw={manageDateKeyboardSpin}
                        className={
                          isStartDateDisabled ? 'date-css disabled' : 'date-css'
                        }
                        selected={reportFilterValues.startDateTime}
                        onChange={e => handleStartDateChange(e)}
                        required
                        minDate={subMonths(new Date(), 24)}
                        maxDate={addMonths(new Date(), 6)}
                        styles={customStyles}
                        disabled={isStartDateDisabled}
                      />
                    </Box>
                  </Center>
                )
              case 18:
                let isEndDateDisabled =
                  !!reportFilterValues.selectedTimescale &&
                  reportFilterValues.selectedTimescale.value !== 0
                    ? true
                    : false
                return (
                  <Center>
                    <Box>
                      <Text>{filterItem.tag_type_filter_label}</Text>
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        onChangeRaw={manageDateKeyboardSpin}
                        className={
                          isEndDateDisabled ? 'date-css disabled' : 'date-css'
                        }
                        selected={reportFilterValues.endDateTime}
                        onChange={e =>
                          setReportFilterValues(prevData => ({
                            ...prevData,
                            endDateTime: e,
                          }))
                        }
                        required
                        minDate={
                          !!reportFilterValues.startDateTime &&
                          reportFilterValues.startDateTime
                        }
                        maxDate={addMonths(reportFilterValues.startDateTime, 6)}
                        disabled={isEndDateDisabled}
                      />
                    </Box>
                  </Center>
                )
              case 25:
                return (
                  <Center>
                    <Box>
                      <Text>{filterItem.tag_type_filter_label}</Text>
                      <Select
                        isSearchable={false}
                        name="processes"
                        value={reportFilterValues.selectedRole}
                        onChange={e => {
                          setReportFilterValues(prevData => ({
                            ...prevData,
                            selectedRole: e,
                          }))
                        }}
                        options={reportRolesList}
                        styles={customStyles}
                      />
                    </Box>
                  </Center>
                )
              default:
                return null
            }
          })
        : null} */}
			</SimpleGrid>
		</Box>
	)
}

export default ReportFilters
