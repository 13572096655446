/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import {
	Button,
	Box,
	Center,
	IconButton,
	Skeleton,
	Table,
	Tag,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
	Icon,
	HStack,
	VStack,
	Input,
	Textarea,
	FormControl,
	FormLabel,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	DrawerHeader,
	Divider,
	DrawerFooter,
} from '@chakra-ui/react'
import { FiLoader } from 'react-icons/fi'
import { MdFileDownload, MdEmail } from 'react-icons/md'
import { BiHistory } from 'react-icons/bi'
import { Pagination } from '../../../components/pagination'
import { downloadAwsFile } from '../../../utils/aws.util'
import {
	formatExportCoverDate,
	formatDDMMYYYYHHMM,
	getReportDatteISTtoUTC,
} from '../../../utils/date.utils'
import { useSendEmail } from '../../../hooks/form.hooks'
import { useAuthContext } from '../../../context/auth.context'
import { useLanguageContext } from '../../../context/locale.context'
import { AlertBox } from '../../../components/AlertBox'
import {
	getLocalAuthDetails,
	validateEmailUsernamePassword,
} from '../../../utils/common.util'
import { customEmailTemplate } from '../../../utils/email-templates.utils'
import { useLoadAssetInlineCollection } from '../../../hooks/asset.hooks'
// import {
// 	dataLayerTagManager,
// 	sectionDetailedTrack,
// } from '../../repository/repo.utils'
import { GA_CATEGORY_REPORT } from './../../repository/repo.utils'

const ReportData = ({
	reportData = [],
	isLoading,
	getMoreData,
	disable,
	updateDownloadCount,
	pageSize,
	setPageSize,
	setCurrentPage,
	currentPage,
}) => {
	const [tableData, setTableData] = useState([])

	const {
		state: { authData },
	} = useAuthContext()

	const { mutate: mutateSendEmail } = useSendEmail()
	const { mutate: loadInlineData } = useLoadAssetInlineCollection()
	const [sendEmail, setSendEmail] = useState(false)
	const [email, setEmail] = useState('')
	const [receiverName, setReceiverName] = useState('')
	const [senderName, setSenderName] = useState('')

	const [subject, setSubject] = useState('')
	const [message, setMessage] = useState('')
	const [error, setError] = useState('')
	const [username, setUsername] = useState('')

	const [alertMessage, setAlertMessage] = useState('')
	const [attachmentFileUrl, setAttachmentFileUrl] = useState('')
	const { isOpen: isAlertOpen, onOpen, onClose: alertClose } = useDisclosure()
	const {
		isOpen: isSendEmail,
		onOpen: onOpenEmail,
		onClose: onCloseSendEmail,
	} = useDisclosure()

	const {
		state: { locale },
	} = useLanguageContext()

	const onOpenSendEmail = s3Url => {
		setSendEmail(true)
		setAttachmentFileUrl(s3Url)
		onOpenEmail()
	}

	const loadUserDetails = useCallback(data => {
		const payload = getLocalAuthDetails()
		loadInlineData(
			{ ...payload },
			{
				onSuccess: async res => {
					let userData = res.data.response.data
					localStorage.setItem(
						'ORGANIZATION_ONHOLD',
						userData && userData.organization_flag_dashboard_onhold
					)
					if (
						!!userData?.operating_asset_username &&
						!!userData?.asset_email_password
					) {
						setUsername(userData?.operating_asset_username)
					} else {
						setUsername('')
					}
				},
				onError: async err => {
					console.log(err, 'err')
				},
			}
		)
	})

	useEffect(() => {
		setSenderName(authData.operating_asset_first_name)
		loadUserDetails()
	}, [])

	const validateSendMail = payload => {
		if (payload.outlook_email_integration === 1) {
			const { valid, msg } = validateEmailUsernamePassword(authData)
			if (valid) return true
			else {
				setAlertMessage(msg)
				onOpen()
				return false
			}
		} else if (payload.outlook_email_integration === 0) {
			return true
		}
		return false
	}

	const sendEmailv8 = payload => {
		mutateSendEmail(
			{
				payload,
			},
			{
				onSuccess: async data => {
					if (data.status === 200) {
						setAlertMessage('E-mail sent successfully!')
						onOpen()
						resetForm()
						// onClose();
					} else {
						setAlertMessage(data.response[0].message)
						onOpen()
						resetForm()
					}
				},
			}
		)
	}

	const sendMailParams = async () => {
		let emails
		if (email) {
			emails = email.split(',')
		}
		return {
			asset_id: authData.asset_id,
			organization_id: authData.organization_id,
			asset_token_auth: authData.asset_token_auth,
			customer_name: receiverName,
			email_subject: subject,
			email_body: message,
			email_receivers: emails,
			email_sender_name: 'GreneOS',
			outlook_email_integration: 0,
			email_sender: 'support@greneos.com',
			flag: 0,
			email_sender_username: username,
			email_sender_asset_id: authData.asset_id,
			html_template: btoa(
				customEmailTemplate({
					org_image: authData.organization_image_path || null,
					org_id: authData.organization_id,
					org_name: authData.organization_name,
					senderName: 'GreneOS',
					recieverName: receiverName,
					formsForReview: [],
					reviewUrl: '',
					message: message,
					formsForUpdate: [],
				})
			),
			s3_url_attachment: attachmentFileUrl,
			with_size_validation: 1,
		}
	}
	const resetForm = () => {
		// setMappedForms([]);
		setEmail('')
		setReceiverName('')
		setSenderName(authData.operating_asset_first_name || '')
		//setUpdateForms([]);
		//setReviewForms([]);
		setSubject('')
		setMessage('')
	}

	const customClose = () => {
		alertClose()
		onCloseSendEmail()
		// loadAllForms();
	}
	const onSendMail = async () => {
		const { operating_asset_first_name } = authData
		if (
			!email ||
			!senderName ||
			!receiverName ||
			// !selectedReviewForms ||
			// !selectedUpdateForms ||
			!subject ||
			!message
			//!Object.keys(selectedReviewForms).length
		) {
			setError('All Fields are mandatory!')
		} else {
			setError('')
			const payload = await sendMailParams()

			if (validateSendMail(payload)) {
				sendEmailv8(payload)
			}
		}
	}

	useEffect(() => {
		if (reportData.length > pageSize) {
			const startIndex = (currentPage - 1) * pageSize
			const endIndex =
				startIndex + pageSize > reportData.length
					? reportData.length
					: startIndex + pageSize
			let tempTable = reportData.slice(startIndex, endIndex)
			setTableData(tempTable)
		} else {
			setTableData(reportData)
		}
	}, [reportData])

	const changePage = page => {
		const startIndex = (page - 1) * pageSize
		const endIndex =
			startIndex + pageSize > reportData.length
				? reportData.length
				: startIndex + pageSize
		let tempTable = reportData.slice(startIndex, endIndex)
		setTableData(tempTable)
		setCurrentPage(page)
	}

	const onPageSizeChange = e => {
		let newPageSize = Number(e)
		const startIndex = 0
		const endIndex =
			startIndex + newPageSize > reportData.length
				? reportData.length
				: startIndex + newPageSize
		let tempTable = reportData.slice(0, endIndex)
		setTableData(tempTable)
		setCurrentPage(1)
		setPageSize(newPageSize)
	}

	const onUpdateDownloadCount = rowData => {
		updateDownloadCount(rowData)
		downloadAwsFile(rowData.data_entity_text_1)
	}

	const renderTableBody = () => {
		return tableData.length > 0 ? (
			tableData.map((row, index) => {
				let inlineReportData = JSON.parse(row.report_inline_data)
				const reportName = inlineReportData.report_name
				const processName = inlineReportData.process_name
				const isDownload =
					(row.report_status_id === 3 ||
						row.report_status_id === 5 ||
						row.report_status_id === 6) &&
					!!row.data_entity_text_1
						? true
						: false

				return (
					<Tr
						bgColor='white'
						borderRadius='md'
						boxShadow='sm'
						key={Math.random() * Date.now() + '1A'}
					>
						<Td textAlign='center' p='2'>
							{(currentPage - 1) * pageSize + index + 1}{' '}
						</Td>
						<Td textAlign='center' p='2'>
							{reportName}
						</Td>
						<Td textAlign='center' p='2'>
							{processName}
						</Td>
						<Td textAlign='center' p='2'>
							{formatExportCoverDate(row.report_next_start_datetime)}
						</Td>
						<Td textAlign='center' p='2'>
							{getReportDatteISTtoUTC(row.report_next_end_datetime)}
						</Td>
						<Td textAlign='center' p='2'>
							{formatDDMMYYYYHHMM(row.report_created_datetime)}
						</Td>
						<Td textAlign='center' p='2'>
							{formatDDMMYYYYHHMM(row.log_datetime)}
						</Td>
						<Td textAlign='center' p='2'>
							{row.report_download_count}
						</Td>
						<Td textAlign='center' p='2'>
							{row.report_last_download_datetime
								? formatDDMMYYYYHHMM(row.report_last_download_datetime)
								: 'NA'}
						</Td>
						<Td textAlign='center' p='2'>
							<Tag
								borderRadius='full'
								variant='solid'
								color='white'
								bg={localStorage.getItem('color')}
							>
								{row.report_status_name}
							</Tag>
						</Td>
						<Td textAlign='center' p='2'>
							<IconButton
								borderRadius='md'
								bg='white'
								boxShadow='md'
								size='sm'
								onClick={() => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_REPORT,
									// 	action: 'Report Data',
									// 	label: 'Download Report',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Report',
									// 	buttonName: 'Download Report',
									// })
									isDownload && onUpdateDownloadCount(row)
								}}
								icon={isDownload ? <MdFileDownload /> : <FiLoader />}
							/>
							{isDownload ? (
								<IconButton
									borderRadius='md'
									bg='white'
									boxShadow='md'
									size='sm'
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_REPORT,
										// 	action: 'Report Data',
										// 	label: 'Send Mail',
										// })
										onOpenSendEmail(row.data_entity_text_1)
									}}
									icon={<MdEmail />}
								/>
							) : null}
						</Td>
					</Tr>
				)
			})
		) : (
			<Tr>
				<Td colSpan={9}>{'No Reports Found'}</Td>
			</Tr>
		)
	}

	if (!!isLoading) {
		return (
			<Box w='full' h='calc(100vh - 180px)'>
				{[1, 2, 3, 4, 5].map(item => {
					return <Skeleton minH='50px' w='full' my={3} />
				})}
			</Box>
		)
	}

	return (
		<>
			{!!sendEmail ? (
				<Drawer
					className={`form-add-onfly-drawer`}
					isOpen={isSendEmail}
					placement='right'
					onClose={onCloseSendEmail}
					size='md'
				>
					<DrawerOverlay className={`form-add-onfly-drawer-overlay`} />
					<DrawerContent className={`form-add-onfly-drawer-content`}>
						<DrawerHeader fontSize='lg' fontWeight='900'>
							{locale['Compose E-Mail']}
						</DrawerHeader>
						<Divider />
						<DrawerCloseButton
							className={`form-add-onfly-drawer-close-button`}
						/>
						<DrawerBody className={`form-add-onfly-drawer-body`}>
							<VStack my={5}>
								<FormControl isRequired>
									<FormLabel fontWeight='400' fontSize='md' my={1}>
										{locale['Reciever email']}
									</FormLabel>
									<Input
										type='email'
										placeholder='Enter email'
										value={email}
										onChange={e => setEmail(e.target.value)}
									/>
									<Text color='gray'>
										{
											locale[
												'Commas can be used to separate multiple recipients'
											]
										}{' '}
									</Text>
								</FormControl>
								<FormControl>
									<FormLabel fontWeight='400' fontSize='md' my={1}>
										{locale['Reciever Name']}
									</FormLabel>
									<Input
										placeholder={locale['Enter Receiver Name']}
										value={receiverName}
										onChange={e => setReceiverName(e.target.value)}
									/>
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight='400' fontSize='md' my={1}>
										{locale['Sender Name']}
									</FormLabel>
									<Input
										placeholder={locale['Enter Sender Name']}
										value={'GreneOS'}
										onChange={e => setSenderName(e.target.value)}
										isDisabled={true}
									/>
								</FormControl>

								<FormControl isRequired>
									<FormLabel fontWeight='400' fontSize='md' my={1}>
										{locale['Add Subject Line']}
									</FormLabel>
									<Input
										placeholder={locale['Add Subject Line']}
										value={subject}
										onChange={e => setSubject(e.target.value)}
									/>
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight='400' fontSize='md' my={1}>
										{locale['Message']}
									</FormLabel>
									<Textarea
										placeholder={locale['Type in a message']}
										value={message}
										onChange={e => setMessage(e.target.value)}
									/>
								</FormControl>
								<FormControl isRequired>
									<FormLabel fontWeight='400' fontSize='md' my={1}>
										{locale['Link for the attachment file']}
									</FormLabel>
									<Textarea
										placeholder={attachmentFileUrl}
										value={attachmentFileUrl}
										//onChange={e => setMessage(e.target.value)}
										isDisabled={true}
									/>
								</FormControl>
							</VStack>
							<Text color='red'>{error}</Text>
						</DrawerBody>
						<DrawerFooter>
							<Box mt={2} w='full'>
								<AlertBox
									isOpen={isAlertOpen}
									onClose={customClose}
									Message={alertMessage}
								/>
								<HStack justifyContent='flex-end'>
									<Button
										variant='outline'
										size='md'
										borderRadius='sm'
										colorScheme={localStorage.getItem('color')}
										bg='white.400'
										w='140px'
										ml={1}
										// onClick={onClose}
										onClick={() => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_REPORT,
											// 	action: 'Report Data',
											// 	label: 'Reset',
											// })
											resetForm()
										}}
									>
										{locale['Reset']}
									</Button>
									<Button
										variant='solid'
										size='md'
										borderRadius='sm'
										colorScheme={localStorage.getItem('color')}
										bg={localStorage.getItem('color')}
										w='140px'
										ml={1}
										onClick={() => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_REPORT,
											// 	action: 'Report Data',
											// 	label: 'Send Mail',
											// })
											onSendMail()
										}}
									>
										{locale['Send Email']}
									</Button>
								</HStack>
							</Box>
						</DrawerFooter>
					</DrawerContent>
				</Drawer>
			) : null}
			<Box>
				{tableData.length > 0 ? (
					<>
						<Box h='calc(100vh - 280px)' overflowY='auto'>
							<Table
								style={{
									position: 'relative',
									borderCollapse: 'separate',
									borderSpacing: '0px 10px',
								}}
							>
								<Thead style={{ zIndex: 1, position: 'sticky' }}>
									<Tr m='2' boxShadow='sm' borderRadius='md'>
										<Th textAlign='center' pos='sticky' top='0' bg='white'>
											#
										</Th>
										<Th textAlign='center' pos='sticky' top='0' bg='white'>
											{locale['Report Name']}
										</Th>
										<Th textAlign='center' pos='sticky' top='0' bg='white'>
											{locale['Process Name']}
										</Th>
										<Th textAlign='center' pos='sticky' top='0' bg='white'>
											{locale['Start Date']}
										</Th>
										<Th textAlign='center' pos='sticky' top='0' bg='white'>
											{locale['End Date']}
										</Th>
										<Th textAlign='center' pos='sticky' top='0' bg='white'>
											{locale['Created Date']}
										</Th>
										<Th textAlign='center' pos='sticky' top='0' bg='white'>
											{locale['Status Update Date']}
										</Th>
										<Th textAlign='center' pos='sticky' top='0' bg='white'>
											{locale['Download Count']}
										</Th>
										<Th textAlign='center' pos='sticky' top='0' bg='white'>
											{locale['Last Download Datetime']}
										</Th>
										<Th textAlign='center' pos='sticky' top='0' bg='white'>
											{locale['Status']}
										</Th>
										<Th textAlign='center' pos='sticky' top='0' bg='white'>
											{locale['Action']}
										</Th>
									</Tr>
								</Thead>
								<Tbody>{renderTableBody()}</Tbody>
							</Table>
						</Box>
						<Pagination
							limit={pageSize}
							page={currentPage}
							total={reportData.length}
							setlimit={onPageSizeChange}
							setpage={changePage}
						/>
						<Box pt={2} mt={-10}>
							<Button
								bgColor={localStorage.getItem('color')}
								minW='150px'
								color='white'
								onClick={() => {
									// sectionDetailedTrack({
									// 	category: GA_CATEGORY_REPORT,
									// 	action: 'Report Data',
									// 	label: 'Load More Reports',
									// })
									// dataLayerTagManager('button_click', {
									// 	viewName: 'Report',
									// 	buttonName: 'Load More Reports',
									// })
									getMoreData()
								}}
								isDisabled={disable}
							>
								{locale['Load More Reports']}
							</Button>
						</Box>
					</>
				) : (
					<Center>
						<Text mt='20px'>{'No Reports Found'}</Text>
					</Center>
				)}
			</Box>
		</>
	)
}

export default ReportData
