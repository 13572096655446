/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
	Box,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	Text,
	VStack,
	useDisclosure,
	HStack,
	Spacer,
} from '@chakra-ui/react'
import {
	useAddReport,
	useLoadReportData,
	useUpdateDownloadCount,
} from '../../hooks/report.hooks'
import ReportData from './report-data'
import ReportFilter from './report-filter'

import { getCurrentDate } from '../../utils/date.utils'
import { sectionDetailedTrack } from '../repository/repo.utils'
import { GA_CATEGORY_DIALOG_BOX } from './../repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'
import {
	compareAsc,
	addMonths,
	subMonths,
	startOfDay,
	endOfDay,
	startOfMonth,
	endOfMonth,
	startOfQuarter,
	endOfQuarter,
	addQuarters,
	subQuarters,
} from 'date-fns'
import ReportFilters from './report-newfilter'
function ReportWorkspace() {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const {
		isOpen: isOpenReport,
		onOpen: onOpenReport,
		onClose: onCloseReport,
	} = useDisclosure()
	const [reportData, setReportData] = useState([])
	const [disable, setDisable] = useState(false)
	const [isFilterClick, setIsFilterClick] = useState(false)
	const [selectedReportId, setSelectedReportId] = useState(null)
	const [pageSize, setPageSize] = useState(10)
	const [currentPage, setCurrentPage] = useState(1)
	const [reportFilterValues, setReportFilterValues] = useState({
		startDateTime: new Date(),
		endDateTime: endOfDay(new Date()),
		selectedDateType: {
			value: 1,
			label: 'Submitted Date',
			tag_type_id: 1,
		},
		selectedReport: {
			value: null,
			label: 'All',
			tag_type_id: 0,
		},
		selectedTimescale: {
			value: 0,
			label: 'Custom',
			tag_type_id: 0,
		},
	})
	const { mutate: mutateLoadReport, isLoading: isLoadReportLoading } =
		useLoadReportData()
	const {
		state: { locale },
	} = useLanguageContext()
	const { mutate: mutateUpdateDownloadCount } = useUpdateDownloadCount()

	const {
		mutate: mutateAddReport,
		isLoading: isAddReportLoading,
		data: addData,
	} = useAddReport()

	useEffect(() => {
		if (reportFilterValues?.selectedReport?.value === 0) {
			loadReportsDataInital()
		} else {
			if (reportFilterValues?.selectedReport?.value === null) {
				setReportData([])
			}
		}
	}, [reportFilterValues?.selectedReport?.value])
	const loadReportsDataInital = () => {
		setIsFilterClick(false)
		mutateLoadReport(
			{
				report_type_id: 0,
				date_type: 1,
				start_datetime: new Date('1970-01-01'),
				end_datetime: reportFilterValues?.endDateTime,
				page_start: 0,
				page_limit: 50,
			},
			{
				onSuccess: data => {
					setReportData(data)
					setReportFilterValues(prevData => ({
						...prevData,
						startDateTime: new Date(),
					}))
				},
			}
		)
	}
	const loadReportsData = () => {
		setIsFilterClick(true)
		mutateLoadReport(
			{
				report_type_id: reportFilterValues?.selectedReport?.value,
				date_type: reportFilterValues?.selectedDateType?.value,
				start_datetime: reportFilterValues?.startDateTime,
				end_datetime: reportFilterValues?.endDateTime,
				page_start: 0,
				page_limit: 50,
			},
			{
				onSuccess: data => {
					setReportData(data)
					setDisable(false)
				},
			}
		)
	}

	const addReport = reportFilter => {
		setSelectedReportId(reportFilter.selectedReport.tag_type_id)
		setCurrentPage(1)
		mutateAddReport(reportFilter, {
			onSuccess: data => {
				if (data === 200) {
					onOpen()
					// mutateLoadReport();
					loadReportsData()
				}
			},
		})
	}

	const getMoreData = () => {
		if (!disable) {
			mutateLoadReport(
				{
					report_type_id: reportFilterValues?.selectedReport?.value,
					date_type: reportFilterValues?.selectedDateType?.value,
					start_datetime: isFilterClick
						? reportFilterValues?.startDateTime
						: new Date('1970-01-01'),
					end_datetime: reportFilterValues?.endDateTime,
					page_limit: 50,
					page_start: reportData.length,
				},
				{
					onSuccess: data => {
						if (!!data.length) {
							setReportData([...reportData, ...data])
						} else {
							setDisable(true)
						}
					},
				}
			)
		}
	}
	const clearFilters = () => {
		setDisable(false)
		setPageSize(10)
		setCurrentPage(1)
		setReportFilterValues(prevData => ({
			...prevData,
			//startDateTime: new Date('1970-01-01'),
			endDateTime: endOfDay(new Date()),
			selectedDateType: {
				value: 1,
				label: 'Submitted Date',
				tag_type_id: 1,
			},
			selectedReport: {
				value: 0,
				label: 'All',
				tag_type_id: 0,
			},
			selectedTimescale: {
				value: 0,
				label: 'Custom',
				tag_type_id: 0,
			},
		}))
		loadReportsDataInital()
	}
	const updateDownloadCount = rowData => {
		mutateUpdateDownloadCount(
			{
				log_datetime: getCurrentDate(),
				report_transaction_id: rowData.report_transaction_id,
				report_id: rowData.report_id,
			},
			{
				onSuccess: async data => {
					if (data?.data.status === 200) {
						//loadReportsData()
					}
				},
			}
		)
	}

	return (
		<Box bg='secondary'>
			<HStack spacing={4} align='stretch' mb='20px'>
				<Spacer />
				<Button
					bg={localStorage.getItem('color')}
					minW='150px'
					color='white'
					onClick={onOpenReport}
					W='150px'
				>
					Add New Report
				</Button>
			</HStack>
			<VStack spacing={4} align='stretch'>
				<Modal isOpen={isOpenReport} onClose={onCloseReport} size='xl'>
					<ModalOverlay />
					<ModalContent>
						<ModalCloseButton />
						<ModalBody>
							<Box p='10px' background='white' borderRadius='md'>
								<ReportFilter
									addReport={addReport}
									isLoading={isAddReportLoading}
								/>
							</Box>
						</ModalBody>
					</ModalContent>
				</Modal>
				<Box p='20px' background='white' borderRadius='md'>
					<ReportFilters
						loadReportsData={loadReportsData}
						isLoading={isAddReportLoading}
						reportFilterValues={reportFilterValues}
						setReportFilterValues={setReportFilterValues}
						clearFilters={clearFilters}
					/>
				</Box>
				<Box p='20px' background='white' borderRadius='md'>
					<ReportData
						reportData={reportData}
						isLoading={isLoadReportLoading}
						getMoreData={getMoreData}
						disable={disable}
						updateDownloadCount={updateDownloadCount}
						pageSize={pageSize}
						setPageSize={setPageSize}
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
					/>
				</Box>
			</VStack>
			<Modal isCentered isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{locale['Report Request successfully submitted.']}
					</ModalHeader>
					<ModalBody>
						{selectedReportId === 0 ? (
							[
								<Text>
									{
										locale[
											'It will be made available to you by 11 am tomorrow in this section'
										]
									}
								</Text>,
								<Text>
									{
										locale[
											'Note: This is once-a-month report available from 21st of the month for the preceding month'
										]
									}
								</Text>,
							]
						) : (
							<Text>
								{
									locale[
										'Please visit Report List section after tomorrow morning 7 A.M.'
									]
								}
							</Text>
						)}
					</ModalBody>
					<ModalFooter>
						<Button
							colorScheme='blue'
							mr={3}
							onClick={() => {
								sectionDetailedTrack({
									category: GA_CATEGORY_DIALOG_BOX,
									action: 'Report',
									label: 'Close',
								})
								onClose()
							}}
						>
							{locale['Close']}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	)
}

export default ReportWorkspace
